.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;

  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #9C95AE;

  @media (max-width: 767px) {
    padding-top: 10px;
    padding-bottom: 10px;
    column-gap: 15px;
  }

  svg {
    transition: all .2s linear;
    flex-shrink: 0;

    @media (max-width: 991px) {
      width: 37px;
      height: 37px;
    }

    @media (max-width: 767px) {
      width: 24px;
      height: 24px;
    }
  }

  &[aria-expanded="true"] {
    border-bottom: 1px solid transparent;
    padding-bottom: 10px;

    svg {
      transform: rotate(-180deg);

      path {
        fill: #5967D1;
      }
    }

    .question {
      color: #5967D1;
    }
  }
}

.item {
  margin-bottom: 16px;
}

.question {
  font-size: 32px;
  font-weight: 600;

  @media (max-width: 991px) {
    font-size: 24px;
  }

  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.content {
  font-size: 26px;
  font-weight: 500;

  @media (max-width: 991px) {
    font-size: 20px;
    max-width: 546px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    max-width: 546px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}
