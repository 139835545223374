.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #6D6D6D80;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  padding: 67px 11px 50px 11px;
  overflow-y: auto;
  overflow-x: hidden;
}

.website-overlay {
  display: flex;
  justify-content: center;
}

.checkbox-wrapper {
  margin-bottom: 40px;
  display: flex;
  column-gap: 10px;
  margin-top: 20px;
}

.checkbox-wrapper.checkbox-wrapper-error {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .checkbox-wrapper {
    max-width: 250px;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .checkbox-wrapper.checkbox-wrapper-error {
    margin-bottom: 16px;
  }
}

.checkbox-error {
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: #D83D3D;
}

@media (max-width: 767px) {
  .checkbox-error {
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.3;
  }
}

.checkbox-label-check {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;

  span {
    color: #595962 !important;
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .checkbox-label-check span {
    font-size: 12px;
  }
}

.custom-checkbox {
  flex-shrink: 0;
  position: relative;
  width: 26px;
  height: 26px;
  border-radius: 4px;
  display: inline-block;
  vertical-align: sub;
  border: 1px solid #9C95AE;
  transition: all 0.2s ease-in;
  background-color: transparent;

  &::before {
    content: "";
    display: block;
    width: 16px;
    height: 11px;
    background-image: url('../assets/check.svg');
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 767px) {
  .custom-checkbox {
    width: 20px;
    height: 20px;
  }

  .custom-checkbox::before {
    width: 12px;
    height: 8px;
  }
}

.real-checkbox {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.real-checkbox[type="checkbox"]:checked + .custom-checkbox {
  background-color: #5967D1;
  border-color: transparent;
}

.real-checkbox[type="checkbox"]:checked + .custom-checkbox::before {
  opacity: 1;
}

.website-modal {
  width: 60%;
  position: fixed;
  top: 5%;
}

.website-image-container::-webkit-scrollbar {
  display: none;
}
.website-image {
  width: 100%;
}
.navigation-icon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 100%;
  z-index: 1;
  height: auto;
}
.modal-content {
  position: relative;
}

.website-modal__content {
  top: 53px;
}

.address-bar {
  background: #EDEDED;
  display: flex;
  align-items: center;
  height: 53px;
  overflow: hidden;
}
.dots {
  border-radius: 50%;
  width: 15.77px;
height: 15.76px;
background: #C4C4C4;
margin: 0 3px;
}
.dots:first-child {
  margin-left: 20px;
}
.url-container {
  background-color: white;
  padding: 5px 10px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 13px;
}
.url-text {
font-size: 14px;
font-weight: 400;
line-height: 16.41px;
text-align: left;
color: #000000;
}
.react-tel-input .flag-dropdown {
  border: none;
  background-color: inherit;
  margin: auto 0;
  height: 26px !important;
}
.react-tel-input .form-control {
  font-size: 24px;
  line-height: 34px;
  height: 26px !important;
  border: none;
}
.modal {
  border-radius: 10px;
  position: relative;
}
.close-modal-icon {
  position: absolute;
  right: -81px;
  top: 2px;
  cursor: pointer;
  z-index: 1;
  pointer-events: auto;

  @media (max-width: 991px) {
    right: 0;
    top: -61px;
  }

  @media (max-width: 767px) {
    top: -61px;
  }
}
.block-modal {
  max-width: 700px;
  width: 100%;
  position: relative;
}
.form-modal {
  position: relative;
}
.close-btn {
  position: absolute;
  top: 0px;
  right: 35px;
  cursor: pointer;
}

.button-container-modal {
  text-align: center;
  margin-top: 50px;
}
.form-modal {
  margin-top: 30px;
}
.warning-message {
  position: fixed;
  top: 150px;
  right: 0%;
  transform: translateX(50%);
  padding: 16px 20px;
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 427px;
  z-index: 1000;
  border-radius: 20px;
  box-shadow: 2px 1px 4px 0px #524E8C2E,
              6px 5px 8px 0px #524E8C26,
              39px 33px 14px 0px #524E8C00;
  animation: slideInRight 0.5s forwards;
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideOutRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}
.warning-text {
  display: flex;
  flex-direction: column;
  width: 284px;
}
.warning-text p {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #0A0C13;
  margin: 0;
}
.warning-text span {
font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: #595962;
}
.warning-icon {
  width: 46px;
  height: 46px;
}
.close-icon {
  width: 24px;

  cursor: pointer;
  user-select: auto;
  pointer-events: auto;
}


@media (max-width: 999px) {
  .warning-message {
    padding: 12px;
    background-color: #ffffff;
    width: 308px;
  border-radius: 16px;
  right: 10px;
  top: 80px;
  }
  .warning-text {
    width: 234px;
  }
  .warning-text p {
    font-size: 16px;
    line-height: 22px;
  }
  .warning-text span {
  font-size: 12px;
  line-height: 16px;
  }
  .warning-icon {
    width: 40px;
    height: 40px;
  }
  .react-tel-input .flag-dropdown {
    border: none;
    background-color: inherit;
    margin: auto 0;
  }
}
@media (max-width: 768px) {
  .website-modal {
    width: 90%;
    height: 100%;
    position: fixed;
    top: 10%;
  }
}
.overlay {
  opacity: 0;
  animation: fadeInOverlay 0.5s forwards;
}
.modal {
  opacity: 0;
  transform: translateY(-50px);
  animation: fadeInModal 0.5s forwards;
}
@keyframes fadeInOverlay {
  to {
    opacity: 1;
  }
}
@keyframes fadeInModal {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.modal.closing {
  animation: fadeOutModal 0.5s forwards;
}
@keyframes fadeOutModal {
  to {
    opacity: 0;
    transform: translateY(-50px);
  }
}
.overlay.closing {
  animation: fadeOutOverlay 0.5s forwards;
}
@keyframes fadeOutOverlay {
  to {
    opacity: 0;
  }
}
