.tariffs {
  padding-top: 198px;

  @media (max-width: 991px) {
    padding-top: 120px;
  }

  @media (max-width: 767px) {
    padding-top: 100px;
  }
}

.header {
  margin-bottom: 40px;

  @media (max-width: 991px) {
    margin-bottom: 16px;
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}
