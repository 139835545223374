.feedback {
  position: relative;
  overflow: hidden;
  background: #0f112f;
  //background-size: cover;
  padding: 100px 0;
  z-index: 2;

  @media (max-width: 991px) {
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    padding: 40px 0 60px;
  }
}

.imageBg {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.title {
  flex-shrink: 0;

  @media (max-width: 1300px) {
    flex-shrink: 1;
  }

  @media (max-width: 467px) {
    max-width: 354px;
  }
}

.header {
  position: relative;
  z-index: 4;
  display: flex;
  align-items: center;
  margin-bottom: 58px;
  justify-content: space-between;
  column-gap: 86px;

  @media(max-width: 1477px) {
    justify-content: center;
  }

  img {
    width: 30%;

    @media(max-width: 1477px) {
      display: none;
    }
  }

  @media (max-width: 991px) {
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    margin-bottom: 26px;
    margin-left: auto;
    margin-right: auto;
  }
}

.info {
  font-size: 34px;
  font-weight: 500;
  color: #fff;

  a {
    color: #fff;
  }

  @media (max-width: 991px) {
    font-size: 26px;
    order: 1;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.emailWrapper {
  margin-bottom: 40px;

  @media (max-width: 991px) {
    margin-bottom: 24px;
  }

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
}

.text {
  margin-bottom: 16px;

  @media (max-width: 767px) {
    margin-bottom: 12px;
  }
}

.feedbackContainer {
  position: relative;
  z-index: 4;

  @media (max-width: 767px) {
    padding: 0 11px;
  }
}

.wrapper {
  display: flex;
  align-items: flex-start;
  column-gap: 64px;

  @media (max-width: 1167px) {
    column-gap: 27px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    row-gap: 40px;
  }

  @media (max-width: 767px) {
    row-gap: 24px;
  }
}

.socials {
  display: flex;
  column-gap: 20px;

  @media (max-width: 767px) {
    column-gap: 16px;
  }

  img {
    @media (max-width: 991px) {
      width: 56px;
      height: 56px;
    }

    @media (max-width: 767px) {
      width: 40px;
      height: 40px;
    }
  }

}
