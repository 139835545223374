.header {
  margin-bottom: 40px;
  display: flex;
  align-items: flex-end;
  column-gap: 20px;

  @media (max-width: 991px) {
    margin-bottom: 24px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    row-gap: 10px;
  }
}

.tariff {
  position: relative;
  overflow: hidden;
  padding: 80px 0;
  background-color: #F1F3FF;

  @media (max-width: 991px) {
    padding: 40px 0;
  }
}

.decor {
  position: absolute;
  left: calc(50% - 706px);
  top: 19px;
  width: 635px;
  height: 426px;

  @media (max-width: 991px) {
    width: 446px;
    height: 317px;

    left: calc(50% - 471px);
    top: 15px;
  }

  @media (max-width: 767px) {
    width: 404px;
    height: 287px;

    left: calc(50% - 333px);
    top: 100px;
    opacity: 0.36;
    object-fit: cover;
  }

  @media (max-width: 500px) {
    top: 150px;
  }
}

.triangle {
  position: absolute;
  right: 0;
  top: 0;

  @media (max-width: 991px) {
    right: -34px;
    top: -34px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;

  @media (max-width: 991px) {
    margin-bottom: 24px;
  }

  @media (max-width: 767px) {
    justify-content: center;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }

  li {
    position: relative;
    padding-left: 34px;
    color: #000;
    font-size: 24px;
    font-weight: 400;

    @media (max-width: 991px) {
      font-size: 16px;
      padding-left: 24px;
      font-weight: 500;
    }

    @media (max-width: 767px) {
      font-weight: 600;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 3px;
      width: 24px;
      height: 24px;
      background-image: url("../../../../app/assets/images/icons/list-tariff-orange.svg");
      background-repeat: no-repeat;

      @media (max-width: 991px) {
        background-size: 16px;
      }
    }
  }
}

.wrapper {
  position: relative;
  z-index: 1;
}

.content {
  max-width: 603px;
  margin-left: auto;

  @media (max-width: 991px) {
    max-width: 420px;
  }

  @media (max-width: 767px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.btnWrapper {
  display: flex;

  @media (max-width: 767px) {
    justify-content: center;
  }
}
