.form {
  margin: 0 -11px;
  max-width: 700px;
  width: 100%;
  padding: 40px 60px;
  background-color: #fff;
  border-radius: 26px;

  @media (max-width: 1167px) {
    padding: 40px;
  }

  @media (max-width: 991px) {
    max-width: 660px;
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    max-width: 500px;
    padding: 24px 16px;
    border-radius: 20px;
  }
}

.title {
  margin-bottom: 20px;
  font-size: 34px;
  font-weight: 500;
  text-align: center;

  @media (max-width: 991px) {
    font-size: 34px;
  }

  @media (max-width: 767px) {
    font-size: 20px;
    margin-bottom: 16px;
  }
}

.checkboxError {
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: #D83D3D;

  @media (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.3;
  }
}

.checkboxWrapperError {
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
}

.btnWrapper {
  margin-top: 40px;
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    margin-top: 24px;
  }
}

.inputField {
  overflow: hidden;
  width: 100%;
  padding: 15px 20px;
  border-radius: 100px;
  border: 1px;
  gap: 10px;
  color: #000;
  outline: none;
  text-align: left;
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  border: 1px solid #9C95AE;

  &::placeholder {
    color: #9C95AE;
  }

  @media (max-width: 767px) {
    padding: 3px 16px;
    font-size: 16px;

    .react-tel-input .form-control {
      font-size: 16px !important;
    }
  }
}

.inputError {
  color: #000;
  border-color: #65071D;
}

.label {
  display: block;
  margin-bottom: 6px;
  font-size: 20px;
  line-height: 1.4;

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.checkboxWrapper {
  display: flex;
  column-gap: 10px;
  margin-top: 20px;

  @media (max-width: 767px) {
    margin-top: 16px;
  }
}

.checkboxLabel {
  font-size: 18px;

  @media (max-width: 767px) {
    font-size: 12px;

    max-width: 250px;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.checkboxInput {
  width: 26px;
  height: 26px;
  background-color: #5967D1;

  @media (max-width: 767px) {
    width: 20px;
    height: 20px;
  }
}

.btn {
  @media (max-width: 991px) {
    width: 444px;
    height: 73px;
  }

  @media (max-width: 767px) {
    width: 252px;
    height: 42px;
  }
}


.checkboxLabel {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;

}

.realCheckbox {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  z-index: -1;
}

 .customCheckbox {
   flex-shrink: 0;
   position: relative;
   width: 26px;
   height: 26px;
   border-radius: 4px;
   display: inline-block;
   vertical-align: sub;
   border: 1px solid #9C95AE;
   transition: all 0.2s ease-in;
   background-color: transparent;

   @media (max-width: 767px) {
     width: 20px;
     height: 20px;
   }

   &::before {
     content: "";
     display: block;
     width: 16px;
     height: 11px;
     background-image: url('../../../assets/check.svg');
     background-size: contain;
     background-repeat: no-repeat;
     opacity: 0;

     @media (max-width: 767px) {
       width: 12px;
       height: 8px;
     }

     position: absolute;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
   }
 }

.realCheckbox[type="checkbox"]:checked + .customCheckbox {
  background-color: #5967D1;
  border-color: transparent;

  &::before {
    opacity: 1;
  }
}

