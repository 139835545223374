.tariff {
  overflow: hidden;
  position: relative;
  padding: 40px 0;
}

.decor {
  position: absolute;
  left: calc(50% - -247px);
  top: 166px;
  width: 817px;
  height: 459px;

  @media (max-width: 1142px) {
    width: 563px;
    height: 316px;
    left: calc(50% + 161px);
    top: 540px;
  }

  @media (max-width: 991px) {
    left: calc(50% + 0px);
    top: 373px;
  }

  @media (max-width: 767px) {
    width: 563px;
    height: 316px;
    left: calc(50% - 162px);
    top: 663px;
    opacity: 0.36;
    object-fit: cover;
  }

  @media (max-width: 500px) {
    top: 750px;
  }
}

.header {
  margin-bottom: 40px;
  display: flex;
  align-items: flex-end;
  column-gap: 20px;

  @media (max-width: 991px) {
    margin-bottom: 24px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    row-gap: 10px;
  }
}

.wrapper {
  position: relative;
  z-index: 1;
}

.content {
  display: flex;
  align-items: flex-start;
  column-gap: 20px;
  max-width: 866px;
  margin-bottom: 40px;


  @media (max-width: 991px) {
    margin-bottom: 24px;
    max-width: 548px;
    column-gap: 10px;
  }

  @media (max-width: 767px) {
    margin-bottom: 24px;
    max-width: 336px;
    margin-left: auto;
    margin-right: auto;
    gap: 16px;
    flex-direction: column;
  }
}

.right {
  max-width: 418px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;


  @media (max-width: 767px) {
    justify-content: center;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }

  li {
    position: relative;
    padding-left: 34px;
    font-size: 24px;
    font-weight: 400;

    @media (max-width: 991px) {
      font-size: 16px;
      padding-left: 24px;
      font-weight: 500;
    }

    @media (max-width: 767px) {
      font-weight: 600;
    }

    &.orange {
      &::before {
        background-image: url("../../../../app/assets/images/icons/list-tariff-orangetwo.svg");
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 3px;
      width: 24px;
      height: 24px;
      background-image: url("../../../../app/assets/images/icons/list-tariff.svg");
      background-repeat: no-repeat;

      @media (max-width: 991px) {
        background-size: 16px;
      }
    }
  }
}

.btn {
  display: flex;

  @media (max-width: 767px) {
    justify-content: center;
  }
}

.left {
  @media  (max-width: 767px) {
    order: 1;
  }
}
