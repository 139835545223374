.title {
  margin-bottom: 40px;
  color: #5967D1;

  @media (max-width: 991px) {
    margin-bottom: 26px;
  }

  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
}

.item {
  display: flex;
  column-gap: 60px;

  @media (max-width: 1300px) {
    column-gap: 40px;
  }

  @media (max-width: 991px) {
    column-gap: 22px;
  }

  //@media (max-width: 767px) {
  //  justify-content: center;
  //  column-gap: 22px;
  //}
  //
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.content {
  padding-top: 20px;
  padding-right: 16px;



  @media (max-width: 991px) {
    padding: 0;
  }

  @media (max-width: 767px) {
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.titleTask {
  line-height: 1.4;
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    font-size: 24px;
    margin-bottom: 16px;
  }

  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.listTask {
  padding-left: 36px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-bottom: 16px;
    padding-left: 26px;
  }

  li {
    max-width: 482px;
    font-size: 26px;
    list-style: disc;
    line-height: 1.7;

    @media (max-width: 991px) {
      font-size: 20px;
    }

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}

.deadline {
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 32px;

  @media (max-width: 991px) {
    font-size: 24px;
    margin-bottom: 26px;
  }

  @media (max-width: 767px) {
    font-size: 20px;
    margin-bottom: 16px;
    max-width: 276px;
  }

  span {
    color: #EA5D2F;
    font-weight: 700;
  }
}

.wrappeqrImage {
  position: relative;
  height: 581px;
  width: 100%;
  max-width: 941px;



  @media (max-width: 1626px) {
    max-width: 700px;
  }

  @media (max-width: 1428px) {
    max-width: 600px;
  }

  @media (max-width: 1300px) {
    max-width: 350px;
  }

  @media (max-width: 991px) {
    height: 530px;
    max-width: 350px;
  }

  @media (max-width: 767px) {
    max-width: 100%;
    height: 253px;
    width: 100%;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;

  @media (max-width: 767px) {
    border-radius: 0px;
  }
}

.magnifying {
  position: absolute;
  right: 20px;
  bottom: 20px;

  @media (max-width: 991px) {
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
  }

  @media (max-width: 767px) {
    left: 16px;
    top: 6px;
    width: 40px;
    height: 40px;
  }
}

.imageCase {
  max-width: 941px;
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 20px;

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

