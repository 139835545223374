.text {
  font-size: 36px;
  line-height: 50px;
  color: #EA5D2F;
  font-weight: 600;
  text-transform: uppercase;

  &.beige {
    color: #FFC37C;
  }

  @media (max-width: 991px) {
    font-size: 28px;
    line-height: 39px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 33px;
  }
}
