.container {
  max-width: 1658px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;

  @media (max-width: 767px) {
    padding: 0 16px;
  }

  &.small {
    @media (max-width: 991px) {
      padding: 0 10px;
    }

    @media (max-width: 767px) {
      padding: 0;
    }
  }


  &.medium {
    max-width: 1371px;

    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }
}

