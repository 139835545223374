.tarif {
  overflow: hidden;
  position: relative;
  padding: 40px 0;
  background-size: cover;
  background: #0f112f;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 40px;
    width: 223px;
    height: 65px;
    z-index: 2;

    background-image: url("../../../../app/assets/images/tariffs/heat.svg");
    background-repeat: no-repeat;

    @media (max-width: 991px) {
      background-size: 122px;
      width: 223px;
      height: 35px;
    }

    @media (max-width: 767px) {
      background-size: 80px;
      width: 80px;
      height: 28px;
      top: 69px;
    }

    @media (max-width: 400px) {
      left: -18px;
    }
  }
}

.imageBg {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.decor {
  position: absolute;
  right: calc(50% - -175px);
  top: 238px;
  width: 839px;
  height: 435px;
  z-index: 2;

  @media (max-width: 1210px) {
    right: calc(50% + 88px);
    top: 417px;
  }

  @media (max-width: 991px) {
    width: 695px;
    height: 391px;

    right: calc(50% + -91px);
    top: 347px;
  }

  @media (max-width: 767px) {
    width: 631px;
    height: 355px;
    left: calc(50% - 337px);
    top: 551px;
    opacity: 0.36;
    object-fit: cover;
  }

  @media (max-width: 500px) {
    top: 650px;
  }
}

.container {
  position: relative;
  z-index: 3;
}

.header {
  display: flex;
  align-items: flex-end;
  column-gap: 20px;
  margin-bottom: 40px;

  @media (max-width: 991px) {
    margin-bottom: 24px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    row-gap: 10px;
  }
}

.content {
  display: flex;
  column-gap: 21px;
  margin-bottom: 40px;

  @media (max-width: 991px) {
    margin-bottom: 24px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
  }
}

.wrapper {
  position: relative;
  z-index: 1;
  max-width: 895px;
  margin-left: auto;

  @media (max-width: 1210px) {
    max-width: 700px;
  }

  @media (max-width: 991px) {
    max-width: 548px;
    margin-left: auto
  }

  @media (max-width: 767px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.left {
  order: 1;
}

.right {
  order: 2;

  @media (max-width: 1210px) {
    order: 0;
  }
}


.list {
  display: flex;
  flex-direction: column;
  gap: 16px;


  @media (max-width: 767px) {
    justify-content: center;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }

  li {
    position: relative;
    padding-left: 34px;
    color: #fff;
    font-size: 24px;
    font-weight: 400;

    @media (max-width: 991px) {
      font-size: 16px;
      font-weight: 500;
      padding-left: 24px;
    }

    @media (max-width: 767px) {
      font-weight: 600;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 3px;
      width: 24px;
      height: 24px;
      background-image: url("../../../../app/assets/images/icons/list-tariff-white.svg");
      background-repeat: no-repeat;

      @media (max-width: 991px) {
        background-size: 16px;
      }
    }
  }
}

.listRight {
  li {
    &::before {
      background-image: url("../../../../app/assets/images/icons/list-tariff-orange.svg");
    }
  }
}

.btnWrapper {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 991px) {
    margin-top: 24px;
  }

  @media (max-width: 767px) {
    justify-content: center;
  }
}
