.section {
  padding-top: 99px;
  padding-bottom: 120px;

  @media (max-width: 991px) {
    padding-top: 120px;
    padding-bottom: 60px;
  }

  @media (max-width: 991px) {
    padding-top: 80px;
  }
}

.title {
  margin-bottom: 41px;

  @media (max-width: 991px) {
    padding-left: 24px;
  }

  @media (max-width: 767px) {
    margin-bottom: 24px;
    padding-left: 16px;
  }
}

