.button {
  width: 444px;
  height: 73px;
  padding: 0 4px;
  border-radius: 100px;
  background-color: #5967D1;
  color: #fff;
  line-height: 1;
  font-size: 28px;
  text-transform: uppercase;
  transition: background-color .2s linear, color .2s linear;
  font-weight: 700;

  &.white {
    background-color: #fff;
    color: #5967D1;
  }

  &:hover {
    background-color: #6779FF;
    color: #fff;
  }

  @media (max-width: 991px) {
    width: 352px;
    height: 67px;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    width: 252px;
    height: 42px;
    font-size: 16px;
  }

  &[disabled] {
    cursor: none;
    pointer-events: none;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  animation: spin 1s linear infinite;

  @media (max-width: 991px) {
    width: 50px;
    height: 50px;
  }

  @media (max-width: 767px) {
    width: 35px;
    height: 35px;
  }
}
