.work {
  padding-top: 40px;
}

.title {
  margin-bottom: 20px;
}

.text {
  font-size: 36px;
  font-weight: 600;

  @media (max-width: 991px) {
    font-size: 24px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.header {
  margin-bottom: 60px;
  max-width: 780px;

  @media (max-width: 991px) {
    margin-bottom: 40px;
    max-width: 537px;
  }

  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
}

.questions {
  flex-shrink: 0;
  max-width: 785px;
  width: 100%;

  @media (max-width: 1520px) {
    max-width: 500px;
  }
}

.tab {
  position: relative;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &[aria-selected="true"] {
    .questionTitle {
      color: #EA5D2F;
    }
  }
}

.imageLinear {
  width: 370px;
  position: relative;
  left: 200px;

  @media (max-width: 1520px) {
    width: 231px;
  }

  @media (max-width: 991px) {
    width: 66%;
    left: 130px;
  }

  @media (max-width: 767px) {
    width: 51%;
    left: 165px;
  }

  @media (max-width: 491px) {
    left: 40px;
    width: 70%;
  }
}

.questionTitle {
  color: #5967D1;
  font-weight: 700;
  font-size: 36px;
  padding: 3px 0;

  @media  (max-width: 1342px) {
    font-size: 29px;
  }

  @media (max-width: 991px) {
    font-size: 24px;
  }

  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.tab:nth-child(even) {
  .questionTitle {
    text-align: right;
  }
}

.description {
  margin-bottom: 40px;
  padding-bottom: 10px;
  font-size: 36px;

  border-bottom: 2px solid #EA5D2F;

  @media  (max-width: 1342px) {
    font-size: 29px;
  }

  @media (max-width: 991px) {
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 4px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.wrapper {
  display: flex;
  align-items: flex-start;
  column-gap: 24px;
}

.answers {
  max-width: 768px;
  margin-left: auto;
}

.accardionBtn {
  &[aria-expanded="true"] {
    img {
      display: none;
    }

    .questionTitle {
      color: #EA5D2F;
    }
  }

  .questionTitle {
    margin-bottom: 16px;
  }
}

.accardionContent {
  max-width: 555px;

  img {
    @media (max-width: 991px) {
      max-width: 509px;
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 4px;
  }
}


.tab {
  &:nth-child(even) {
    .accardionContent {
      margin-left: auto;
    }
  }
}
