.order-background {
  background: #0F112F;
  height: 900px;
    margin-top: 100px;
    background-size: cover;
}

.form h3 {
    text-align: center;
    font-size: 34px;
    font-weight: 600;
    line-height: 1.4;
}

.form-header {
  margin-bottom: 20px;
}

@media (max-width: 670px) {
  .form-header {
    margin-bottom: 16px;
  }
}

@media (max-width: 670px) {
    .form h3 {
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
      font-size: 20px !important;
      font-weight: 500;
    }

    .block-modal {
      max-width: 537px;
    }
}

.form-container-tariff {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 670px) {
  .form-container-tariff {
    margin-top: 8px;
  }
}

.form-container-tariff-name {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.3;
}

@media (max-width: 670px) {
  .form-container-tariff-name {
    font-size: 14px;
  }
}

.form-container-tariff-price {
  font-size: 24px;
  color: #5967D1;
  line-height: 1.3;
}

@media (max-width: 670px) {
  .form-container-tariff-price {
    font-size: 12px;
  }
}

.order-development-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  .order-section-title {
display: flex;
flex-direction: row;
justify-content: space-between;
  }
  .order-section-title h2 {
    text-transform: uppercase;
    white-space: nowrap;
    color: #fff;
    margin-top: 50px;
font-size: 48px;
font-weight: 500;
line-height: 67px;
letter-spacing: 0em;
  }
  .order-content {
    display: flex;
    justify-content: center;
  }
  .left-text {
    color: #fff;
    font-size: 32px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
  .left-text-1 {
margin-bottom: 50px;
  }
  .order-content .icons-container {
    display: flex;
    flex-direction: row;
    margin: 0;
    gap: 20px;
    margin-top: 20px;
  }
  .order-arrow-icon {
margin-top: 50px;
margin-left: 350px;
  }
  .form-container {
    background: #FFFFFF;
    padding: 40px 60px;
    border-radius: 26px;
    gap: 10px;
    box-shadow:
    0px 1px 3px 0px #9099E133,
    0px 6px 6px 0px #9099E12B,
    0px 13px 8px 0px #9099E11A,
    0px 22px 9px 0px #9099E108,
    0px 35px 10px 0px #9099E100;
  }

  @media (max-width: 767px) {
    .form-container {
      padding: 24px 16px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    border-radius: 51.31px;
    justify-content: flex-start;
  }

  .form span {
    color: #5967D1;
  }
  .input-field {
  overflow: hidden;
width: 100%;
padding: 15px 20px;
border-radius: 100px;
border: 1px;
gap: 10px;
color: #000;
border: 1px solid #9C95AE;
outline: none;
text-align: left;
font-size: 24px;
font-weight: 400;
line-height: 34px;
letter-spacing: 0em;
text-align: left;
  }

  @media (max-width: 767px) {
      .input-field {
          padding: 9px 20px;
      }
  }



  .input-error {
    color: #000;
    border: 1px solid #65071D;
  }
    .input-label {
        display: block;
        margin-bottom: 6px;
    }

  .input-group-error .input-label, .input-group-error .input-label span{
    color: #65071D;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0em;
    float: right;
  }

  .input-group-error-phone .input-label{
    color: #65071D;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0em;
    font-weight: 600;
    float: right;
    margin-top: -30px;
  }
  .input-field::placeholder {
    color: #9C95AE;
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
  }



  .input-label {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    color: #595962;
    text-align: left;
  }

  @media (max-width: 670px) {
    .input-label {
      font-size: 14px;
      margin-bottom: 0;
    }

    .input-field {
      padding: 3px 20px;
      font-size: 16px;
    }

    .input-field::placeholder {
      font-size: 16px;
    }

    .react-tel-input .form-control {
      font-size: 16px;
    }
  }



  .input-label span {
font-size: 20px;
font-weight: 600;
line-height: 28px;
letter-spacing: 0em;
text-align: center;
color: #5967D1;
  }
  .input-group {
      margin-bottom: 10px;
  }

  .checkbox-container {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: #595962;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .checkbox-input {
    width: 26px;
    height: 26px;
    margin-right: 10px;
    cursor: pointer;
  }
    .checkbox-container {
      display: flex;
      align-items: center;
    }
    .checkbox-input:checked {
      background-color: #5967D1 !important;
    }
    .checkbox-label {
      cursor: pointer;
    }


  @media (max-width: 991px) {
    .submit-button {
      width: 444px !important;
      height: 73px !important;
    }
  }

@media (max-width: 767px) {
  .submit-button {
    width: 252px !important;
    height: 42px !important;
  }
}

  .button-container {
    text-align: center;
  }
  .right-block-form {
    position: relative;
  }
  .order-left-block {
    position: absolute;
    left: 162px;
  }
  @media (max-width: 1728px) {
    .order-left-block {
      position: absolute;
      left: 60px;
    }
    }
    @media (max-width: 1728px) {
      .order-section-title {
  margin: 0 -100px;
          }
      }
      @media (max-width: 1600px) {
        .left-text {
          font-size: 28px;
        }
        }
        @media (max-width: 1600px) {
          .left-text {
            font-size: 24px;
          }
          }
    @media (max-width: 1440px) {
      .order-left-block {
        position: absolute;
      }
    .left-text {
      font-size: 24px;
    }
    .order-section-title {
      margin: 0 -150px;
              }
      }
      @media (max-width: 1280px) {
        .order-section-title-h2 {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
        .order-section-title h2 {
font-size: 38px;
font-weight: 600;
line-height: 53px;
letter-spacing: 0em;
text-align: left;
margin: 50px auto;
        }
          .order-content {
              position: relative;
            flex-direction: column-reverse;
            align-items: center;
          }
          .order-section-title img {
            width: 200px;
          }
          .order-content .right-block {
justify-content: center;
align-items: center;
          }
          .order-left-block {
margin-right: auto;
position: relative;
bottom: -40px;
          }
          .order-background {
            height: 1100px;
          }
          .order-left-block .left-text {
            line-height: 36px;
          }
    .order-section-title {
      margin: 0 0px;
              }
      }
      @media (max-width: 1024px) {
        .order-left-block {
          left: 24px;
        }
      }
      @media (max-width: 950px) {
        .order-section-title {
          margin: 0 -50px;
                  }
      }
      @media (max-width: 800px) {
        .order-section-title {
          margin: 0 -100px;
      }
    }
