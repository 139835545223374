.footer {
  padding: 60px 0 100px;

  @media (max-width: 991px) {
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    padding: 40px 0 50px;
  }
}

.top {
  margin-bottom: 130px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 50px;

  @Media (max-width: 991px) {
    margin-bottom: 60px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 40px;
    margin-bottom: 40px;
  }
}

.left {
  max-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media (max-width: 991px) {
    width: auto;
  }

  @media (max-width: 767px) {
    row-gap: 12px;
  }
}

.description {
  font-size: 24px;

  @media (max-width: 991px) {
    font-size: 20px;
  }

  @media (max-width: 767px) {
    text-align: center;
    font-size: 16px;
  }
}

.right {
  max-width: 424px;
  width: 100%;

  @media (max-width: 767px) {
    max-width: 100%;
  }
}

.socials {
  margin-bottom: 40px;
  display: flex;
  column-gap: 48px;

  @media (max-width: 991px) {
    margin-bottom: 20px;
    column-gap: 12px;
    justify-content: right;

    img {
      width: 40px;
      height: 40px;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 16px;
    column-gap: 10px;
    justify-content: center;
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  font-size: 24px;

  @media (max-width: 991px) {
    max-width: 248px;
    font-size: 20px;
    text-align: right;

    p {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 767px) {
    max-width: 100%;
    align-items: center;
    row-gap: 16px;
    text-align: center;
    justify-content: center;
  }

  a {
    color: #0A0C13;
  }
}

.image {
  @media (max-width: 767px) {
    text-align: center;
  }
}

.nav {
  display: flex;
  align-items: center;
  column-gap: 40px;


  @media (max-width: 1200px) {
    column-gap: 16px;
  }

  @media (max-width: 767px) {
    flex-direction: row;
    max-width: 223px;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
  }

  li {
    font-size: 20px;
    cursor: pointer;
    color: #595962;


    &:nth-child(2) {
      @media (max-width: 991px) {
        order: 1
      }

      @media (max-width: 767px) {
        order: 0
      }
    }

    a {
      color: #595962
    }

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}

.copy {
  color: #595962;
  font-size: 20px;

  @media (max-width: 991px) {
    display: none;
  }

  @media (max-width: 767px) {
    max-width: 200px;
    font-size: 16px;
    display: block;
    text-align: center;
    order: 1;
  }
}

.copyTablet {
  display: none;

  @media (max-width: 991px) {
    display: block;
    margin-top: 24px;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 991px) {
    justify-content: center;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    row-gap: 40px;
  }
}

