.title {
  color: #5967D1;
  line-height: 1.4;
  font-weight: 600;
  text-transform: uppercase;

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  // Размеры
  &.small {
    font-size: 42px;
  }

  &.smallTwo {
    font-size: 42px;
  }

  &.average {
    font-size: 48px;
  }

  // Цвет текста
  &.black {
    color: #0A0C13;
  }

  &.blue {
    color: #5967D1;
  }

  &.white {
    color: #fff;
  }

  @media (max-width: 991px) {
    &.average {
      font-size: 38px;
    }

    &.small {
      font-size: 28px;
    }

    &.smallTwo {
      font-size: 32px;
    }
  }

  @media (max-width: 767px) {
    &.average {
      font-size: 26px;
    }

    &.small {
      font-size: 20px;
    }

    &.smallTwo {
      font-size: 26px;
    }
  }
}


