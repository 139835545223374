.card {
  max-width: 606px;
  width: 606px;
  height: 400px;
  position: relative;
  overflow: hidden;
  border-radius: 13px;
  cursor: auto;

  @media (max-width: 991px) {
      max-width: 400px;
      height: 246px;
  }

  @media (max-width: 767px) {
    max-width: 318px;
    height: 198px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  padding: 20px;
  background-color: rgba(#090A20, 0.6);

  @media (max-width: 991px) {
    padding: 16px 24px;
  }

  @media (max-width: 767px) {
    padding: 10px;
  }
}

.title {
  color: #fff;
  margin-bottom: 10px;
  font-size: 32px;
  font-weight: 600;
  text-transform: uppercase;

  @media (max-width: 991px) {
    margin-bottom: 0;
    font-size: 24px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.subtitle {
  font-size: 24px;
  color: #fff;

  @media (max-width: 991px) {
    font-size: 16px;
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 20px;
  top: 20px;
  width:  236px;
  height: 48px;
  padding: 0 10px;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1.4;
  color: #fff;
  border-radius: 100px;
  font-weight: 700;
  border: 1px solid #fff;

  @media (max-width: 991px) {
    width: 196px;
    height: 42px;
    left: 12px;
    top: 12px;
    font-size: 16px;
  }

  @media (max-width: 767px) {
    height: 38px;
  }
}
