.sliderController {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: #5967D1;
  transition: background-color .2s linear;

  &:hover {
    background-color: #6779FF;
  }

  @media (max-width: 991px) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 767px) {
    width: 34px;
    height: 34px;
  }
}

.sliderControllerPrev {
  position: absolute;
  z-index: 7;
  left: -12px;
  top: 42%;

  img {
    transform: rotate(180deg);
  }

  @media (max-width: 991px) {
    left: 12px;
  }

  @media (max-width: 767px) {
    top: 35%;
    display: none;
  }
}

.sliderControllerNext {
  position: absolute;
  z-index: 7;
  right: 12px;
  top: 42%;

  @media (max-width: 767px) {
    top: 235px;
  }
}

.swiper {
  position: relative;
}

.isShowPrev {
  display: none;
}

.isShowNext {
  display: none;
}


