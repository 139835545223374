.hero {
  position: relative;
  padding-top: 103px;
  height: 1096px;
  overflow: hidden;
  display: flex;
  padding-left: max(24px, calc((100% - 1610px) / 2));

  @media (max-width: 1728px) {
    padding-top: 99px;
  }

  @media (max-width: 1220px) {
    padding-top: 96px;
  }

  @media (max-width: 991px) {
    height: 900px;
  }

  @media (max-width: 700px) {
    padding-top: 0;
    height: 658px;
  }

  @media (min-height: 690px) {
    height: 100vh;
  }

  @media (max-width: 700px) and (min-height: 570px) {
    height: calc(100vh - 64px);
  }

  @media (max-width: 425px) and (min-height: 500px) {
    height: 100vh;
  }
}

.imageDecor {
  display: block;
  position: absolute;
  min-width: 865px;
  z-index: -1;
  bottom: -67px;
  right: -30px;
  max-width: unset;
  height: 45%;

  @media (max-width: 991px) {
    min-width: 500px;
    z-index: -1;
    bottom: -2px;
    //height: 337px;
    right: -2px;
  }
}

.left {
  position: relative;
  padding-top: 120px;
  width: 803px;

  @media (max-width: 1334px) {
    width: 500px;
  }

  @media (max-width: 662px) {
    width: 308px;
    padding-right: 10px;
  }
}

.right {
  position: relative;
  width: calc(100% - 803px);

  @media (max-width: 1334px) {
    width: calc(100% - 500px );
  }

  @media (max-width: 991px) {
    width: calc(100% - 431px);
  }

  @media (max-width: 767px) {
    width: calc(100% - 15px);
  }

  @media (max-width: 662px) {
    width: calc(100% - 270px);
  }
}

.imgLaptop {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.title {
  font-size: 60px;
  line-height: 1.4;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;

  @media (max-width: 1334px) {
    font-size: 48px;
  }

  @media (max-width: 991px) {
    margin-bottom: 16px;
    font-size: 40px;
  }

  @media (max-width: 767px) {
    margin-bottom: 12px;
    font-size: 26px;
  }

  span {
    color: #5967D1;
  }
}

.subtitle {
  margin-bottom: 40px;
  font-size: 32px;
  font-weight: 600;

  @media (max-width: 991px) {
    margin-bottom: 24px;
    font-size: 24px;
  }

  @media (max-width: 767px) {
    margin-bottom: 16px;
    font-size: 16px;
  }
}

