.questions {
  overflow: hidden;
  position: relative;
  padding: 160px 0 60px;
  min-height: 800px;

  @media (max-width: 991px) {
    min-height: auto;
    padding: 120px 0 60px;
  }

  @media (max-width: 767px) {
    padding: 100px 0 60px;
  }
}

.questionImg {
  position: absolute;
  top: 200px;
  right: 0;
  width: 586px;
  height: 695px;

  @media (max-width: 1336px){
    top: unset;
    bottom: 0;
  }

  @media (max-width: 991px) {
    width: 419px;
    height: 510px;
  }

  @media (max-width: 500px) {
    img {
      filter: blur(2px);
    }
  }
}

.wrapper {
  position: relative;
  z-index: 2;
}

.header {
  margin-bottom: 40px;

  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
}

.content {
  max-width: 1060px;

  @media (max-width: 1582px) {
    max-width: 800px;
  }

  @media (max-width: 1336px){
    padding: 10px 16px;
    background-color: rgba(#fff, 0.8);
  }

  @media (max-width: 991px) {
    max-width: 546px;
  }

  @media (max-width: 767px) {
    margin-left: -16px;
    margin-right: -16px;
  }
}
