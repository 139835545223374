.content {
  display: flex;
  padding-left: max(24px, calc((100% - 1610px) / 2));
  padding-top: 120px;

  @media (max-width: 991px) {
    padding-top: 60px;
    padding-left: max(10px, calc((100% - 1610px) / 2));
  }

  @media (max-width: 767px) {
    padding-top: 40px;
    padding-left: max(10px, calc((100% - 1610px) / 2));
  }
}

.slide {
  width: auto;
  cursor: pointer;
}

.test {
  color: red;
}

.project {
  flex-shrink: 0;
}

.swiper {
  margin-left: unset;
}
