.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  position: absolute;
  bottom: 60px;
  height: clamp(7.375rem, 5.223rem + 9.18vw, 9.625rem);
  width: 100%;
  z-index: 5;
  background-color: rgb(13 17 39 / 80%);

  @media (max-width: 991px) {
    left: 0;
    bottom: 0;
  }
}

.item {
  color: #D5CFE6;
  font-size: 24px;
  line-height: 1.4;
  text-transform: uppercase;

  @media (max-width: 767px) {
    font-size: 16px;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  span {
    color: #EA5D2F;
  }
}
