.header {
  margin-bottom: 40px;
  display: flex;
  align-items: flex-end;
  column-gap: 20px;

  @media (max-width: 991px) {
    margin-bottom: 24px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    row-gap: 10px;
  }
}

.wrapper {
  position: relative;
  z-index: 1;
}

.tariff {
  position: relative;
  overflow: hidden;
  padding: 40px 0;
  background-color: #F1F3FF;
}

.decor {
  display: block;
  position: absolute;
  left: calc(50% + -59px);
  top: 65px;
  width: 870px;
  height: 490px;
  background-repeat: no-repeat;

  @media (max-width: 991px) {
    background-size: 695px;
    width: 695px;
    height: 391px;

    left: calc(50% - 40px);
    top: 87px;
  }

  @media (max-width: 767px) {
    background-size: 631px;

    width: 631px;
    height: 355px;
    left: calc(50% - 91px);
    top: 222px;
    opacity: 0.36;
    object-fit: cover;
  }

  @media (max-width: 500px) {
    top: 300px;
  }
}

.listWrapper {
  margin-bottom: 40px;

  @media (max-width: 991px) {
    margin-bottom: 24px;
  }
}
.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;

  @media (max-width: 991px) {
    margin-bottom: 24px;
  }

  @media (max-width: 767px) {
    justify-content: center;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }

  li {
    position: relative;
    padding-left: 34px;
    color: #000;
    font-size: 24px;
    font-weight: 400;

    @media (max-width: 991px) {
      font-size: 16px;
      padding-left: 24px;
      font-weight: 500;
    }

    @media (max-width: 767px) {
      font-weight: 600;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 3px;
      width: 24px;
      height: 24px;
      background-image: url("../../../../app/assets/images/icons/list-tariff.svg");
      background-repeat: no-repeat;

      @media (max-width: 991px) {
        background-size: 16px;
      }
    }
  }
}

.btnWrapper {
  display: flex;

  @media (max-width: 767px) {
    justify-content: center;
  }
}
